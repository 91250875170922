@import "shared/styles/core";

.recommended {
  background: var(--gray-g200);
  padding: ($spacing * 8) 0;
}

.heading {
  font-size: var(--font-size-h3-sm);
  font-family: var(--font-family-sections-heading);
  font-weight: var(--font-weight-hero);
  margin-bottom: ($spacing * 4);
  text-transform: uppercase;
}

.carousel {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  scrollbar-width: none;
  scroll-padding: 0 25%;
  scroll-snap-type: x mandatory;

  &.emptyState {
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    margin: {
      right: $spacing * 4;
      bottom: $spacing * 6;
    }

    scroll-snap-align: center;
    flex: 0 0 $featured-content-width;
    height: auto;
  }
}

@include media-breakpoint-up(sm) {
  .carousel {
    &.emptyState {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ($default-grid-gap / 2);

      .item {
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .recommended {
    padding: ($spacing * 12) 0;
  }

  .heading {
    font-size: var(--font-size-hero);
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: ($spacing * 6);
  }

  .carousel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $default-grid-gap;

    &.emptyState {
      grid-gap: $default-grid-gap;
    }

    .item {
      margin: 0;
    }
  }
}
