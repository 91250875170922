@import "shared/styles/core";

.container {
  padding: ($spacing * 5) 0;
}

.header {
  color: #1cac0f;
  margin-bottom: ($spacing * 5);
}

.element {
  display: flex;
  flex-direction: column;
  margin-bottom: ($spacing * 5);
  gap: ($spacing * 2);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-g200);
    padding-bottom: ($spacing * 3);
    margin-bottom: ($spacing * 3);
  }
}

.link {
  text-decoration: none;
  color: var(--content-primary);

  &:hover,
  &:active {
    color: var(--content-primary);
  }
}

.title {
  font-family: var(--font-family-body-bold);
}

.timeContainer {
  color: var(--content-tertiary);
  display: flex;
  align-items: center;
  gap: ($spacing);

  .readingTime {
    display: flex;
    align-items: center;
    gap: ($spacing);
  }
}

.more {
  color: var(--link-on-surface);
  font-family: var(--font-family-body-bold);
  text-decoration: none;
}

@include media-breakpoint-up(xl) {
  .container {
    padding: ($spacing * 6) 0;
  }
}
