@import "shared/styles/core";

$image-height: 228px;
$tile-max-width: 344px;

.tile {
  position: relative;
  width: calc(100vw - ($spacing * 8));
  background-color: var(--surface-tertiary);
  border-radius: ($spacing * 4);
  box-shadow: 0 2px 12px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
}

.bookmarkButton {
  position: absolute;
  top: ($spacing * 3);
  right: ($spacing * 3);
}

.image {
  display: block;
  height: $image-height;
  object-fit: cover;
  position: relative;
  border-radius: ($spacing * 4) ($spacing * 4) 0 0;

  &.episodeImage {
    height: auto;
    width: 100%;
    padding-top: 100%;
  }

  img {
    border-radius: ($spacing * 4) ($spacing * 4) 0 0;
  }
}

.meta {
  padding: ($spacing * 2) ($spacing * 3) ($spacing * 5);
  flex: 1;
}

.episodeMeta {
  position: relative;
  padding: ($spacing * 3) ($spacing * 3) ($spacing * 5);
  flex: 1;

  .menu {
    position: absolute;
    top: ($spacing * 2);
    right: ($spacing * 2);
  }
}

.bottom,
.episodePlayer {
  padding: 0 ($spacing * 3) ($spacing * 3);
}

.tag,
.title,
.podcastTitle,
.author {
  color: inherit;
  text-decoration: none;
  overflow: hidden;
}

.tag {
  margin-right: $spacing;
}

.sneakPeak {
  margin-top: ($spacing * 2);
}

.author {
  font-family: var(--font-family-body-bold);
  display: flex;
  align-items: center;
  gap: ($spacing * 2);
  padding-bottom: ($spacing * 3);

  .avatar {
    border: none;
    height: ($spacing * 8);
    width: ($spacing * 8);
  }
}

.podcastTitle {
  margin-bottom: ($spacing * 2);
}

.time {
  display: flex;
  gap: $spacing;
  margin-top: ($spacing * 2);
  color: var(--content-tertiary);
}

@include media-breakpoint-up(sm) {
  .tile {
    width: $tile-max-width;
  }
}

@include media-breakpoint-up(lg) {
  .tile {
    max-width: 100%;
    width: auto;
  }

  .meta {
    padding: ($spacing * 2) ($spacing * 4) ($spacing * 6);
  }

  .episodeMeta {
    padding: ($spacing * 3) ($spacing * 4) ($spacing * 6);
  }

  .bottom,
  .episodePlayer {
    padding: 0 ($spacing * 4) ($spacing * 4);
  }
}
