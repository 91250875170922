@import "shared/styles/core";
$font-size-podcast-title-mobile: 12px;
$font-size-podcast-title-tablet: 14px;
$podcast-tile-small-image-space: $podcast-tile-mobile-image +
  $podcast-tile-grid-gap;
$podcast-tile-big-image-space: $podcast-tile-desktop-image +
  $podcast-tile-grid-gap;

.podcastTile {
  border: $default-border-width solid var(--core-black);
  display: flex;
  flex-direction: column;
  background-color: var(--surface-tertiary);

  &:not(:last-child) {
    margin: 0 0 ($spacing * 5);
  }
}

.image {
  cursor: pointer;
  position: relative;
  border: 0;
  object-fit: cover;
  object-position: top;
  height: $tile-image-size-sm;
  width: 100%;
}

.meta {
  padding: ($spacing * 3);
  border-top: $default-border-width solid var(--core-black);
  background-color: var(--surface-tertiary);
}

.podcast {
  display: inline-block;
  margin: ($spacing * 2) 0 $spacing;
  width: 100%;
  font-size: $font-size-podcast-title-mobile;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  color: var(--color-podcast-tile-title);

  &.sport {
    color: var(--link-on-surface);
  }
}

.episodeLink {
  display: block;
  overflow: hidden;
  height: auto;
}

.podcastLink,
.episodeLink {
  text-decoration: none;
  color: var(--content-primary);
}

/* Dark Tile for Main Feed */
.dark {
  &.podcastTile {
    background: var(--core-black);
    color: var(--core-white);
    border: 0;
  }

  .image {
    border-color: var(--surface-secondary);
  }

  .podcastLink,
  .episodeLink {
    color: var(--core-white);
  }
}

/* Minimal Variant mainly for other places than feeds */
.minimal {
  .podcastLink {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .episodeLink {
    height: ($spacing * 15);
  }

  .meta {
    padding: ($spacing * 3) ($spacing * 3) ($spacing * 5);
  }

  .podcast {
    display: flex;
    align-items: center;
    margin: 0 0 $spacing;
    font-size: $font-size-podcast-title-tablet;
  }

  .podcastTile {
    > * {
      // Reposition player on podcast tile
      &:last-child {
        padding: 0 0 0 ($spacing * 3);
      }
    }

    &:not(:last-child) {
      margin: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .image {
    height: $tile-image-size;
    width: 100%;
  }
}
