@import "shared/styles/core";

.container {
  padding-bottom: ($spacing * 8);
  grid-gap: ($spacing * 8);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.steps {
  display: grid;
  grid-gap: $spacing;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: ($spacing * 4);
}

.step {
  border: none;
  height: $spacing;
  background-color: var(--gray-g400);

  &.active {
    background-color: var(--link-on-surface);
  }
}

@include media-breakpoint-up(sm) {
  .steps {
    display: none;
  }
}
