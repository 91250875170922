@import "shared/styles/core";

.wrapper {
  background-color: var(--surface-tertiray);
  text-align: center;
  padding: ($spacing * 10) 0;
}

.container {
  max-width: ($spacing * 148);
  margin: auto;
}

.heading {
  margin: ($spacing * 3) 0;
}

.desc {
  margin-bottom: ($spacing * 5);
}

.header {
  color: var(--content-secondary);
  font-family: var(--font-family-sections-heading);
  font-weight: var(--font-weight-hero);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.icon {
  max-width: var(--error-size);
  margin: ($spacing * 8) 0 ($spacing * 10);
}

.link {
  text-decoration: none;

  .button {
    margin: auto;
  }
}

.help,
.errorHelp {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 448px;
  margin: ($spacing * 50) auto ($spacing * 12);
  padding: 0 ($spacing * 4);
  gap: ($spacing * 3);

  .helpIcon {
    min-width: ($spacing * 8);
  }

  .helpButton {
    border: none;
    background: none;
    color: var(--content-primary);
    font-family: var(--font-family-body-bold);
    text-decoration: underline;
    cursor: pointer;
  }
}

.errorHelp {
  margin-top: ($spacing * 10);
}

@include media-breakpoint-up(lg) {
  .icon {
    margin: ($spacing * 10) 0 ($spacing * 16);
  }

  .help {
    margin-top: ($spacing * 58);
  }
}
