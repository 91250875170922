@import "shared/styles/core";

$max-tile-width: 320px;

.wrapper {
  background-color: var(--surface-secondary);
  padding-top: ($spacing * 5);
  margin-bottom: ($spacing * 5);
}

.started {
  padding: ($spacing * 5) ($spacing * 4) ($spacing * 6) 0;
  display: flex;
  gap: ($spacing * 5);
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-padding: 0 25%;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: "";
    min-width: calc((100vw - $container-max-width) / 2);
    display: inline-block;
  }
}

.articleTile {
  min-width: $max-tile-width;
  max-width: $max-tile-width;
  width: 100%;
  padding: ($spacing * 3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--core-white);
  border-radius: $default-border-radius;
  position: relative;
  background: no-repeat center;
  background-size: cover;

  .backgroundImage {
    border-radius: $default-border-radius;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    border-radius: $default-border-radius;
  }
}

.articleTitle {
  z-index: 1;
}

.podcastTile {
  max-width: $max-tile-width;
}

.action {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ($spacing * 3);
  z-index: 1;

  .button {
    color: var(--core-white);
    border-color: var(--core-white);
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    padding-top: ($spacing * 6);
    margin-bottom: ($spacing * 10);
  }

  .started {
    padding: ($spacing * 7) ($spacing * 4) ($spacing * 8) 0;
  }
}
