@import "shared/styles/core";

$font-size-player-text: 12px;

.player {
  display: flex;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  border-top: $default-border-width solid var(--core-black);
  position: relative;
  margin-top: auto;
  margin-bottom: 0;
  background-color: var(--surface-tertiary);
}

.podcastPlayer {
  display: flex;
  width: 100%;
}

.playerControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  padding: 0 $podcast-tile-grid-gap;
}

.playerInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 ($spacing * 1.5);
  font-size: $font-size-player-text;
}

.uploadTime {
  color: var(--color-dark-gray);
}

.duration {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;

  & > svg {
    color: var(--content-primary);
    margin: 0 0 0 $spacing;
  }
}

.mixerControls {
  width: 100%;
  height: ($spacing * 0.5);
  position: absolute;
  bottom: -($spacing * 2);
  left: 0;

  &.pushTop {
    bottom: 0;
  }
}

.mixerTrack {
  height: ($spacing * 0.5);
  display: flex;
  width: 100%;
  background: transparent;
  opacity: 0;
  transition: opacity 0.3s ease-in;

  &.playing {
    opacity: 1;
  }
}

.mixerProgress {
  height: ($spacing * 0.5);
  width: 100%;
  align-self: center;
}

.mixerThumb {
  height: ($spacing * 2);
  width: ($spacing * 2);
  border: 0;
  box-shadow: none;
  border-radius: 100%;
  background: var(--content-primary);

  &:focus {
    box-shadow: none;
    outline: 0;
  }

  &.white {
    background: var(--core-white);
  }
}

.playButton {
  order: -1;

  svg {
    pointer-events: none;
  }
}

.bookmarkButton {
  order: -1;
}

button.playButton,
button.bookmarkButton {
  border: 0;
  border-left: $default-border-width solid var(--core-black);
  box-shadow: none;
  min-width: 50px;
  order: 1;
  background-color: transparent;

  .dark & {
    border-left: $default-border-width solid var(--core-black);
  }
}

.audio {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

/* Main Feed Dark Theme */
.dark {
  &.player {
    border-color: var(--surface-secondary);
  }

  .podcastPlayer {
    .duration {
      & > svg {
        color: var(--core-white);
      }
    }
  }
}

.medium {
}

@include media-breakpoint-up(md) {
  .player {
    display: flex;
    align-items: center;
    align-self: flex-end;
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 0;
    width: 100%;
    margin: auto auto 0;
  }

  button.playButton,
  button.bookmarkButton {
    order: 1;
    border: 0;
    border-left: $default-border-width solid var(--core-black);

    .dark & {
      border: 0;
      border-left: $default-border-width solid var(--surface-secondary);
    }
  }
}
