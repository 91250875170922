@import "shared/styles/core";

.feedContainer {
  padding: ($spacing * 4) 0 0;
  background-color: var(--surface-primary);
  &.personalFeed {
    padding: 0;
  }
}

.savedContainer {
  background-color: var(--surface-tertiary);
}

.feedTiles {
  margin: 0 0 ($spacing * 6);

  .feedHeading {
    text-transform: capitalize;
    font-family: var(--font-family-heading);
    font-size: var(--font-size-h3-sm);
    display: block;
    margin-bottom: ($spacing * 5);
  }
}

.emptyState {
  padding-bottom: ($spacing * 11);
}

.popular {
  display: none;
}

.loadMore {
  width: 100%;
}

.top {
  background-color: var(--gray-g100);
  padding: ($spacing * 6) 0;

  .heading {
    display: flex;
    align-items: flex-start;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: ($spacing * 4);
    color: var(--content-secondary);

    .count {
      padding-left: ($spacing * 3);
    }
  }

  .search {
    margin-bottom: ($spacing * 3);
  }
}

.emptyState {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
}

.personalFeedContainer {
  padding-bottom: ($spacing * 6);
}

.personalFeedEmptyState {
  padding-bottom: ($spacing * 10);
}

.feedFilters {
  display: grid;
  width: 100%;
  grid-gap: ($spacing * 2);
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: ($spacing * 5);

  .button {
    border: none;
    background-color: var(--gray-g100);
    border-radius: ($spacing * 1.5);
    padding: ($spacing * 2);
    font-family: var(--font-family-body-bold);
    font-size: var(--font-size-button);
    color: var(--content-primary);
    line-height: ($spacing * 5);
    cursor: pointer;

    &:hover {
      background-color: var(--gray-g200);
    }

    &.active {
      background-color: var(--content-primary);
      color: var(--content-secondary);
    }
  }
}

@include media-breakpoint-up(sm) {
  .feedFilters {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include media-breakpoint-up(md) {
  .threeTiles {
    display: grid;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 $default-grid-gap;
  }

  .personalFeedContainer {
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .threeTiles {
    grid-template-columns: repeat(3, 1fr);
  }

  .feedContainer {
    padding: ($spacing * 10) 0;
  }

  .feedTiles {
    margin: 0 0 ($spacing * 12);
    grid-column: 1 / 10;

    .feedHeading {
      font-size: var(--font-size-h4);
    }
  }

  .tiles {
    margin: 0 0 ($spacing * 12);
  }

  .top {
    padding: ($spacing * 12) 0 ($spacing * 6);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $default-grid-gap;

    .filters {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

@include media-breakpoint-up(xl) {
  .popular {
    margin-top: ($spacing * 8);
    display: block;
    grid-column: 10 / 13;
  }

  .popularArticles {
    margin: 0;
    grid-column: 10 / 13;
  }

  .top {
    .heading {
      margin-bottom: 0;
    }
  }
}
