@import "shared/styles/core";

$avatar-size: 24px;
$avatar-medium-size: 40px;
$avatar-large-size: 80px;
$avatar-x-large-size-sm: 162px;
$avatar-x-large-size-md: 176px;

.wrapper {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  border: $default-border-width solid var(--core-black);
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
}

.avatar {
  width: $avatar-size;
  height: $avatar-size;
  object-position: center;

  &.medium {
    width: $avatar-medium-size;
    height: $avatar-medium-size;
  }

  &.large {
    width: $avatar-large-size;
    height: $avatar-large-size;
  }

  &.xlarge {
    width: $avatar-x-large-size-sm;
    height: $avatar-x-large-size-sm;
  }
}

.avatarBase {
  opacity: 1;
  border: $default-border-width solid var(--core-black);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

@include media-breakpoint-up(md) {
  .avatar {
    &.xlarge {
      width: $avatar-x-large-size-md;
      height: $avatar-x-large-size-md;
    }
  }
}
