@import "shared/styles/core";

.tag {
  display: inline-flex;
  padding: $spacing ($spacing * 3);
  font-size: var(--font-size-article-feed-tag);
  font-family: var(--font-family-button);
  font-style: var(--font-style-sections-heading);
  font-weight: 700;
  line-height: ($spacing * 5);
  background-color: var(--tag-on-surface);
  color: var(--link-on-surface);
  border-radius: $default-border-radius;
  text-transform: capitalize;

  &.sport {
    text-transform: uppercase;
  }

  &:hover {
    background-color: var(--tag-on-surface-hover);
  }

  &:active {
    background-color: var(--tag-on-surface-pressed);
  }
}
