@import "shared/styles/core";

.heading {
  display: none;
  margin-bottom: ($spacing * 6);
  text-transform: uppercase;
}

@include media-breakpoint-up(lg) {
  .heading {
    display: block;
    font-size: var(--font-size-h3);
  }
}
