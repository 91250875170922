@import "shared/styles/core";
$article-image-mobile-height: 228px;

.tile {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-g200);
  border-radius: $default-border-radius;
  background-color: var(--surface-tertiary);
  position: relative;
  height: 100%;

  &:not(:last-child) {
    margin: 0 0 ($spacing * 5);
  }
}

.image {
  display: block;
  width: 100%;
  height: $article-image-mobile-height;
  object-fit: cover;
  position: relative;

  .img {
    border-radius: $default-border-radius $default-border-radius 0 0;
  }
}

.meta {
  padding: ($spacing * 5) ($spacing * 3) ($spacing * 4);
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: ($spacing * 2);
}

.link {
  text-decoration: none;
  color: inherit;
}

.author {
  color: var(--link-on-surface);
  font-size: var(--font-size-small);
  line-height: ($spacing * 4);
  font-family: var(--font-family-body-bold);
  margin: ($spacing * 0.5) 0;
}

.time {
  color: var(--content-tertiary);
  display: flex;
  align-items: center;
  gap: $spacing;
}

.sneakPeak {
  margin-bottom: ($spacing * 2);
}

.description {
  flex: 1;
}

.tags {
  position: absolute;
  top: -($spacing * 3.5);
  left: ($spacing * 3);
  display: flex;
  gap: ($spacing);
}

.bookmark {
  position: absolute;
  top: ($spacing * 3);
  right: ($spacing * 3);
  background: var(--surface-primary);
}

.readingTime {
  display: flex;
  gap: $spacing;
  align-items: center;
  color: var(--content-tertiary);
}

@include media-breakpoint-up(md) {
  .tile.medium {
    display: grid;
    height: 340px;
    grid-template-columns: repeat(2, 1fr);

    .image {
      height: auto;

      .img {
        border-radius: $default-border-radius 0 0 $default-border-radius;
      }
    }

    .meta {
      padding: ($spacing * 4) ($spacing * 3) ($spacing * 4) ($spacing * 5);
      display: flex;
      flex-direction: column;
    }

    .author {
      order: -1;
      margin: 0 0 $spacing;
    }

    .sneakPeak {
      margin-bottom: ($spacing * 3);
    }

    .tags {
      position: static;
    }

    .bookmark {
      top: ($spacing);
      right: ($spacing);
    }
  }

  .bookmark {
    background: none;
  }

  .small {
    .title {
      font-size: var(--font-size-h4-sm);
    }

    .bookmark {
      background: var(--surface-primary);
    }
  }
}
